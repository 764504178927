import styled from '@emotion/styled';

export const GridContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const GridItem = styled.div`
  flex-grow: 0;
  flex-basis: 300px;
  text-align: center;
  margin: 0 auto 50px auto;
  h3 {
    margin: 20px 0 0 0;
    color: ${props => props.theme.brand_color};
    font-weight: bold;
  }
  img {
    border-radius: 5px;
    max-width: 100%;
    max-height: 100%;
  }
`;