import React, { useContext }    from 'react';
import PropTypes                from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Img                      from 'gatsby-image';
import { Flex, Box }            from '@chakra-ui/react';

import { findT }           from '../../_helpers';
import Dropcap             from '../../text/Dropcap/Dropcap';
import { LanguageContext } from '../../../providers';
import Separator           from '../../structure/Separator/Separator';
import Carousel            from '../../media/Carousel/Carousel';

import * as S             from './style';
import ConditionalWrapper from '../../structure/ConditionalWrapper/ConditionalWrapper';

const TeamMembers = ({ layout, dropcap, listImageSectionWidth }) => {
  const { language } = useContext(LanguageContext);
  return <StaticQuery query={query} render={data => {
    const teamMembers = data.allDirectusTeamMembers.nodes;
    switch (layout) {
      default:
      case 'list':
        return (
          <Flex flexWrap='wrap' fluid>
            {teamMembers.map((member, i) => {
              const transl = findT(member.translations, language);
              return (
                <React.Fragment key={member.name}>
                  <Box width={['100%', `${100 - listImageSectionWidth}%`, `${100 - listImageSectionWidth}%`, `${100 - listImageSectionWidth}%`]} pr={50}>
                    <ConditionalWrapper condition={dropcap} wrapper={(children) => <Dropcap>{children}</Dropcap>}>
                      <h3>{member.name}</h3>
                    </ConditionalWrapper>
                    <div style={{ textAlign: 'justify' }}
                         dangerouslySetInnerHTML={{ __html: transl.description }}/>
                  </Box>
                  {member.portrait && <Box width={['100%', `${listImageSectionWidth}%`, `${listImageSectionWidth}%`, `${listImageSectionWidth}%`]}>
                    <Img fluid={member.portrait.localFile.imageForList.fluid} alt={member.name}/>
                  </Box>}
                  {i + 1 !== teamMembers.length && <Separator spacing={20}/>}
                </React.Fragment>
              )
            })}
          </Flex>
        );
      case 'grid':
        return (
          <S.GridContainer>
            {teamMembers.map((member) => {
              let transl;
              if (member.translations.length > 0) {
                transl = findT(member.translations, language);
              }
              return (
                <S.GridItem key={member.name}>
                  <Img fluid={member.portrait.localFile.imageForGrid.fluid} alt={member.name}/>
                  <h3>{member.name}</h3>
                  {transl && <div dangerouslySetInnerHTML={{ __html: transl.description }}/>}
                </S.GridItem>
              )
            })}
          </S.GridContainer>
        );
      case 'slider':
        return (
          <Carousel>
            {teamMembers.map(member => (
              <div key={member.name}>
                <Img fluid={member.portrait.localFile.imageForSlider.fluid} alt={member.name}/>
                <p>{member.name}</p>
              </div>
            ))}
          </Carousel>
        )
    }
  }}/>
};

TeamMembers.propTypes = {
  layout: PropTypes.oneOf(['list', 'grid', 'slider']),
  dropcap: PropTypes.bool,
  listImageSectionWidth: PropTypes.number,
};

TeamMembers.defaultProps = {
  layout: 'list',
  dropcap: true,
  listImageSectionWidth: 50
};

const query = graphql`
  query {
    allDirectusTeamMembers(filter: {id: {ne: "dummy"}}) {
      nodes {
        name
        portrait {
          localFile {
            name
            imageForSlider: childImageSharp {
              fluid(maxWidth: 1200, quality: 95) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
            imageForList: childImageSharp {
              fluid(maxWidth: 530, quality: 95) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
            imageForGrid: childImageSharp {
              fluid(maxWidth: 400, maxHeight: 400, cropFocus: CENTER, quality: 95) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
          }
        }
        translations {
          language
          description
        }
      }
    }
  }
`;

export default TeamMembers;
