import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import TeamMembers from '@interness/web-core/src/components/modules/TeamMembers';
import Wrapper from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Heading from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer from '@interness/web-core/src/components/structure/Spacer/Spacer';
import CallToAction from '@interness/theme-twentytwenty/src/components/CallToAction/CallToAction';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Wrapper style={{
      textAlign: 'justify'
    }} width={1024} mdxType="Wrapper">
  <Spacer mdxType="Spacer" />
  <Heading mdxType="Heading">Über uns</Heading>
  <Heading tag='h2' mdxType="Heading">Zeit und Funkeln</Heading>
  "Zeit und Funkeln“ lautet unser Motto,unter dem wir Sie rund um das Thema
  Schmuck und Uhren begleiten möchten. Wir präsentieren Ihnen eine exklusive
  Auswahl aus Schmuck- und Uhrenkollektionen, deren große Vielfalt Sie in unserem Geschäft finden. Tauchen Sie ein in
  die Atmosphäre unserer n eu gestalteten Räume. Nehmen Sie sich die Zeit, bei uns zu verweilen und zu entdecken und wir
  nehmen uns gern die Zeit, Sie ausführlich zu beraten.
  Wir freuen uns auf Ihren Besuch.
  <Spacer mdxType="Spacer" />
  <Heading tag='h2' mdxType="Heading">Unsere Stärken:</Heading>
  kompetente, individuelle Beratungsgespräche durch freundliches und aufmerksames Fachpersonal, in denen die
  Zufriedenheit des Kunden immer an erster Stelle steht
  die Leidenschaft und Freude, mit der wir unsere Kollektionen auswählen und präsentieren
  kompetenter Service rund um Uhren und Schmuck durch unsere Uhrmacher und Goldschmiede sowie unsere Servicepartner
  <Spacer mdxType="Spacer" />
  <Heading tag='h2' mdxType="Heading">Das Team</Heading>
  <TeamMembers listImageSectionWidth={35} mdxType="TeamMembers" />
  <Spacer mdxType="Spacer" />
    </Wrapper>
    <CallToAction mdxType="CallToAction" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      